import request from '@/utils/request';

// 分页列表
export function dictList(params) {
  return request({
    url: '/admin/dict/list',
    method: 'GET',
    params: params
  });
}

// 获取字段数据
export function getDictItems(dictCode) {
  return request({
    url: "/admin/dict/getDictItems/".concat(dictCode),
    method: 'GET',
    params: {}
  });
}

//新增字典
export function addDict(dictCode) {
  return request({
    url: "/admin/dict/add",
    method: 'GET',
    params: {}
  });
}

// 字典项列表
export function getDict(dictCode) {
  return request({
    url: "/admin/dict/getDictItems/".concat(dictCode),
    method: 'GET'
  });
}