export function downloadImg(imgsrc) {
  var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
  var image = new Image();
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = imgsrc;
  image.onload = function () {
    var canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, image.width, image.height);
    var url = canvas.toDataURL('image/png' || 'image/jpg');
    var a = document.createElement('a');
    a.setAttribute('target', '_blank');
    a.download = name;
    a.href = url;
    a.click();
  };
}
export var downloadFile = function downloadFile(url) {
  // let curHost=window.location.host
  // let fileName=`module_20230920.zip`
  // let url=`${curHost}/service/getZipInfo?fileName=${fileName}`
  // 创建下载按钮a标签进行自动点击下载，下载完后移除按钮a标签
  var downloadDom = document.createElement('a');
  downloadDom.setAttribute('target', '_blank');
  downloadDom.href = url;
  // downloadDom.download=fileName //--不是必须 若需要【前端重命名文件夹】的话这句代码就需要
  document.body.appendChild(downloadDom);
  downloadDom.click();
  document.body.removeChild(downloadDom);
};
export function checkSuffix(str) {
  var strRegex = /\.(jpg|png|gif|bmp|jpeg|JPEG|PNG|JPG|BMP)$/;
  // var strRegex = /\.(jpg|png|gif|bmp|jpeg)$/
  if (strRegex.test(str.toLowerCase())) {
    return true;
  } else {
    return false;
  }
}
export function checkSuffixXLSX(str) {
  var strRegex = /\.(xls|xlsx)$/;
  // var strRegex = /\.(jpg|png|gif|bmp|jpeg)$/
  if (strRegex.test(str.toLowerCase())) {
    return true;
  } else {
    return false;
  }
}
export function checkSuffixMp4(str) {
  var strRegex = /\.(mp4|MP4|Mp4|mP4)$/;
  // var strRegex = /\.(jpg|png|gif|bmp|jpeg)$/
  if (strRegex.test(str)) {
    return true;
  } else {
    return false;
  }
}