import Layout from '@/layout';
var tagRouter = {
  path: '/tag',
  component: Layout,
  redirect: '/tag/list',
  name: 'Tag',
  meta: {
    title: '标签',
    icon: 'clipboard'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/tag/index');
    },
    name: 'TagList',
    meta: {
      title: '标签管理',
      icon: ''
    }
  }, {
    path: 'myChannel',
    component: function component() {
      return import('@/views/tag/myChannel');
    },
    name: 'myChannel',
    meta: {
      title: '我的频道',
      icon: ''
    }
  }]
};
export default tagRouter;