import Layout from '@/layout';
var mobileRouter = {
  path: '/organization',
  component: Layout,
  redirect: '/organization/supplier',
  name: 'organization',
  meta: {
    title: '组织管理',
    icon: 'user'
  },
  children: [
  // {
  //   path: '/organization/manage',
  //   component: () => import('@/views/organization/page.vue'),
  //   name: 'organization',
  //   meta: { title: '组织管理', icon: 'user' }
  // }
  {
    path: 'supplier',
    component: function component() {
      return import('@/views/organization/supplier-organization/page.vue');
    },
    name: 'supplierOrg',
    meta: {
      title: '品牌会员',
      icon: 'user'
    }
    // meta: { title: '供应商'}
  }, {
    path: 'design',
    component: function component() {
      return import('@/views/organization/design-organization/page.vue');
    },
    name: 'designOrg',
    meta: {
      title: '设计会员',
      icon: 'user'
    }
  }, {
    path: 'procure',
    component: function component() {
      return import('@/views/organization/procure-organization/page.vue');
    },
    name: 'procureOrg',
    meta: {
      title: '云商会员',
      icon: 'user'
    }
  }, {
    path: 'propertyCompany',
    component: function component() {
      return import('@/views/organization/propertyCompany-organization/page.vue');
    },
    name: 'propertyCompanyOrg',
    meta: {
      title: '物业公司',
      icon: 'user'
    }
  }, {
    path: 'realEstatedEvelopers',
    component: function component() {
      return import('@/views/organization/realEstatedEvelopers-organization/page.vue');
    },
    name: 'realEstatedEvelopersOrg',
    meta: {
      title: '房产开发商',
      icon: 'user'
    }
  }, {
    path: 'propertyOperators',
    component: function component() {
      return import('@/views/organization/propertyOperators-organization/page.vue');
    },
    name: 'propertyOperatorsOrg',
    meta: {
      title: '楼盘运营商',
      icon: 'user'
    }
  }, {
    path: 'animationCompanies',
    component: function component() {
      return import('@/views/organization/animationCompanies-organization/page.vue');
    },
    name: 'animationCompaniesOrg',
    meta: {
      title: '制图会员',
      icon: 'user'
    }
  }, {
    path: 'guangzhouDesign',
    component: function component() {
      return import('@/views/organization/guangzhouDesign-organization/page.vue');
    },
    name: 'guangzhouDesign',
    meta: {
      title: '广州设计周',
      icon: 'user'
    }
  }, {
    path: 'partnerCity',
    component: function component() {
      return import('@/views/organization/partnerCity-organization/page.vue');
    },
    name: 'partnerCityOrg',
    meta: {
      title: '城市代理商',
      icon: 'user'
    }
  }]
};
export default mobileRouter;