import request from '@/utils/request';

// 分页列表
export function dictList(params) {
  return request({
    url: '/admin/dict/list',
    method: 'GET',
    params: params
  });
}

// 获取字段数据
export function getDictItems(dictCode) {
  return request({
    url: "/admin/dict/getDictItems/".concat(dictCode),
    method: 'GET',
    params: {}
  });
}

//新增字典
export function addDict(dictCode) {
  return request({
    url: "/admin/dict/add",
    method: 'GET',
    params: {}
  });
}
//删除字典
export function deleteDict(params) {
  return request({
    url: "/admin/dict/delete",
    method: 'DELETE',
    params: params
  });
}

// 更新字典
export function editDict(data) {
  return request({
    url: "/admin/dict/edit",
    method: 'POST',
    data: data
  });
}

// 字典项列表
export function getDict(dictCode) {
  return request({
    url: "/admin/dict/getDictItems/".concat(dictCode),
    method: 'GET'
  });
}

// 新增数字字典项
export function addDictItem(data) {
  return request({
    url: "/admin/dictItem/add",
    method: 'POST',
    data: data
  });
}
// 更新数字字典项
export function editDictItem(data) {
  return request({
    url: "/admin/dictItem/edit",
    method: 'POST',
    data: data
  });
}
// 查询字典项列表
export function getDictItemList(params) {
  return request({
    url: "/admin/dictItem/list",
    method: 'get',
    params: params
  });
}
// 删除字典项
export function deleteDictItem(params) {
  return request({
    url: "/admin/dictItem/delete",
    method: 'DELETE',
    params: params
  });
}

// 查询数字字典列表
export function getDictList(params) {
  return request({
    url: "/admin/dict/list",
    method: 'get',
    params: params
  });
}
// 新增数字字典
export function addDicts(data) {
  return request({
    url: "/admin/dict/add",
    method: 'POST',
    data: data
  });
}
// 删除缓存
export function refleshCacheDict() {
  return request({
    url: "/admin/dict/refleshCache",
    method: 'get'
  });
}