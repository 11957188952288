import Layout from '@/layout';
var mobileRouter = {
  path: '/house',
  component: Layout,
  redirect: '/house/houseList',
  name: 'house',
  meta: {
    title: '楼盘管理',
    icon: ''
  },
  children: [{
    path: 'houseList',
    component: function component() {
      return import('@/views/house/houseList/index.vue');
    },
    name: 'houseList',
    meta: {
      title: '楼盘列表',
      icon: ''
    }
  }, {
    path: 'editHouse',
    component: function component() {
      return import('@/views/house/editHouse/index.vue');
    },
    name: 'editHouse',
    meta: {
      title: '创建楼盘',
      icon: ''
    }
  }]
};
export default mobileRouter;